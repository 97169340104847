import React, { lazy } from 'react';
import { Navigate } from 'react-router-dom';

import Loadable from '../components/Loadable';
import MainLayout from '../layout/MainLayout';

const Projects = Loadable(lazy(() => import('../pages/projects')));
const ProjectCreatePage = Loadable(lazy(() => import('../pages/project-create')));
const Project = Loadable(lazy(() => import('../pages/project')));
const ProjectFilesSearch = Loadable(lazy(() => import('../pages/project/Search')));
const ProjectFiles = Loadable(lazy(() => import('../pages/project/Files')));
const ProjectReport = Loadable(lazy(() => import('../pages/project/Report')));
const ProjectChat = Loadable(lazy(() => import('../pages/project/Chat')));
const AuthenticatedRoute = Loadable(lazy(() => import('../components/auth0/AuthenticatedRoute')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
  path: '/',
  element: (
    <AuthenticatedRoute>
      <MainLayout />
    </AuthenticatedRoute>
  ),
  children: [
    {
      path: '/',
      element: <Navigate replace to="/projects" />
    },
    {
      path: '/projects',
      element: <Projects />
    },
    {
      path: '/projects/create',
      element: <ProjectCreatePage />
    },
    {
      path: '/projects/:id',
      element: <Project />,
      children: [
        {
          path: '',
          element: <Navigate to="files" replace />
        },
        {
          path: 'files',
          element: <ProjectFiles />
        },
        {
          path: 'search',
          element: <ProjectFilesSearch />
        },
        {
          path: 'report',
          element: <ProjectReport />
        },
        {
          path: 'chat',
          element: <ProjectChat />
        }
      ]
    },
    {
      path: '*',
      element: <Navigate to="/" replace />
    }
  ]
};

export default MainRoutes;
