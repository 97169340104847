import objects from './objects';

// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
  // import and add items to this list to add them to the sidebar
  items: [objects]
};

export default menuItems;
