import React from 'react';
import envConfig from 'config/config';
import { Typography } from '@mui/material';

const Environment = () => {
  const environmentWarning = (text: string) => {
    return (
      <Typography variant="h5" sx={{ color: 'activeScheme.onPrimary' }}>
        {'<'}
        {text}
        {'>'}
      </Typography>);
    };

    if (envConfig.env === 'development') {
      return environmentWarning('Development');
    }
    if (envConfig.env === 'staging') {
      return environmentWarning('Staging');
    }
    return null;
};

export default Environment;
