// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
// import { getAnalytics } from 'firebase/analytics';
import { getAuth } from 'firebase/auth';
import { initializeAppCheck, ReCaptchaEnterpriseProvider } from 'firebase/app-check';
import envConfig from '../config/config';
// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional

// Firebase API keys are not secret
const firebaseConfig = envConfig.firebaseConfig;

// Initialize Firebase
// https://firebase.google.com/support/guides/security-checklist?_gl=1*11bh1n8*_up*MQ..*_ga*MzI5MTc5NDg4LjE3MTMzNzk2ODM.*_ga_CW55HF8NVT*MTcxMzM3OTY4My4xLjAuMTcxMzM3OTY4My4wLjAuMA..#api-keys-not-secret
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

const initFirebase = () => {
  console.log('initializing firebase');

  // Analytics
  // Documentation: https://firebase.google.com/docs/analytics/get-started?platform=web&_gl=1*1s2isgs*_up*MQ..*_ga*NDIxNjM0OTUwLjE3MTM0MDUwOTc.*_ga_CW55HF8NVT*MTcxMzQwNTA5Ni4xLjAuMTcxMzQwNTA5Ni4wLjAuMA..
  // Dashboard: https://console.firebase.google.com/u/0/project/dd-prototype-43595/analytics/app/web:MWRjMWJlNDMtYTM5My00YmI0LTg1ZDAtODg1NWViYmUxYTNk/overview/reports~2Fdashboard%3Fr%3Dfirebase-overview&fpn%3D425406153630
  // const analytics = getAnalytics(app);
  // Example manual event:
  // import { logEvent } from 'firebase/analytics';
  // logEvent(analytics, 'test_event');

  // AppCheck:
  // For local dev. Followed these instructions: https://firebase.google.com/docs/app-check/web/debug-provider?_gl=1*160isxb*_up*MQ..*_ga*ODM2NjI0MjE5LjE3MTAzNTY2Njk.*_ga_CW55HF8NVT*MTcxMDM1NjY2OC4xLjAuMTcxMDM1NjY2OC4wLjAuMA..#localhost
  if (process.env.NODE_ENV === 'development') {
    self.FIREBASE_APPCHECK_DEBUG_TOKEN = true;
  }
  // Keys
  // Documentation: firebase.google.com/docs/app-check/web/recaptcha-enterprise-provider?_gl=1*1rcc4ix*_up*MQ..*_ga*ODM2NjI0MjE5LjE3MTAzNTY2Njk.*_ga_CW55HF8NVT*MTcxMDM1NjY2OC4xLjAuMTcxMDM1NjY2OC4wLjAuMA..#install-sdk
  // Keys below are from:
  // - production https://console.cloud.google.com/security/recaptcha/6Lfgp5cpAAAAAG07BUYdvbpcLslWuEf7P_in6U6O/overview?project=dd-prototype-43595
  // - staging    https://console.cloud.google.com/security/recaptcha/6LculawpAAAAAOKTcWzmASpsL_7DFZnWzOdbEBrp/overview?project=dd-prototype-staging&supportedpurview=project
  // And registered with the app here:
  // - production https://console.firebase.google.com/u/0/project/dd-prototype-43595/appcheck/apps
  // - staging    https://console.firebase.google.com/u/0/project/dd-prototype-staging/appcheck/apps
  const appCheckKey = envConfig.recaptchaSiteKey;
  initializeAppCheck(app, {
    provider: new ReCaptchaEnterpriseProvider(appCheckKey),
    isTokenAutoRefreshEnabled: true
  });
};

export { initFirebase, app, auth };
