import React from 'react';
import { Typography, Container, Button, Stack } from '@mui/material';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { useNavigate } from 'react-router-dom';

// https://docs.sentry.io/platforms/javascript/guides/react/features/error-boundary/
// FallbackRender
const ErrorFallback = ({ error, componentStack, eventId, resetError }: any) => {
  console.log('ErrorFallback', error, resetError);
  const navigate = useNavigate();

  const handleNavigate = () => {
    resetError();
    navigate('/projects');
  };
  return (
    <Container
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100svh',
        textAlign: 'center',
        backgroundColor: 'activeScheme.surfaceContainerLowest'
      }}
    >
      <Stack spacing={1} alignItems={'center'}>
        <ErrorOutlineIcon sx={{ fontSize: 80, color: 'error.main' }} />
        <Typography variant="h2">Ooops!</Typography>
        <Typography variant="body1">Something went wrong.</Typography>
        <Typography variant="h5">{"We've been notified and will fix it ASAP :)"}</Typography>
      </Stack>
      <Button variant="contained" color="primary" onClick={handleNavigate} sx={{ mt: 4 }}>
        Back to App
      </Button>
    </Container>
  );
};

export default ErrorFallback;
