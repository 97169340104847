// ==============================|| OVERRIDES - TYPOGRAPHY ||============================== //

export default function Typography() {
  return {
    MuiTypography: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          overflow: 'auto',
          overflowWrap: 'break-word'
        }
      }
    }
  };
}
