import React from 'react';
import { Typography } from '@mui/material';
import { useLocation } from 'react-router-dom';
import { useProjectContext } from '../../../../pages/project/ProjectProvider';

const ProjectName = () => {
  const location = useLocation();
  const { project } = useProjectContext();
  if (!location.pathname.includes('/projects/')) {
    return null;
  }

  return project?.name ? (
    <Typography variant="h3" sx={{ color: 'activeScheme.onPrimary' }}>
      {project.name}
    </Typography>
  ) : null;
};

export default ProjectName;
