import React from 'react';
import PropTypes from 'prop-types';

// material-ui
import { useTheme } from '@mui/material/styles';
import { AppBar, IconButton, Toolbar, useMediaQuery } from '@mui/material';

// project import
import HeaderContent from './HeaderContent';

// assets
import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons';
import { alpha } from '@mui/material/styles';
// ==============================|| MAIN LAYOUT - HEADER ||============================== //

const Header = ({ open, handleDrawerToggle }) => {
  const theme = useTheme();
  const matchDownMD = useMediaQuery(theme.breakpoints.down('lg'));

  const iconBackColor = alpha(theme.palette.activeScheme.primaryContainer, 0.9);
  const iconBackColorOpen = theme.palette.activeScheme.primaryContainer;
  const iconTextColor = theme.palette.activeScheme.onPrimaryContainer;
  // common header
  const mainHeader = (
    <Toolbar sx={{ backgroundColor: 'activeScheme.primary' }}>
      <IconButton
        disableRipple
        aria-label="open drawer"
        onClick={handleDrawerToggle}
        edge="start"
        size="small"
        sx={{
          color: iconTextColor,
          bgcolor: open ? iconBackColorOpen : iconBackColor,
          ml: { xs: 0, lg: -2 },
          p: 1,
          borderRadius: 2,
          // TODO All this work just to juice a button. Surely there's a better way.
          transition: 'background-color 0.3s ease',
          '&:hover': {
            backgroundColor: () => alpha(iconBackColorOpen, 0.8),
            cursor: 'pointer'
          }
        }}
      >
        {!open ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
      </IconButton>
      <HeaderContent />
    </Toolbar>
  );

  const appBar = {
    position: 'sticky',
    color: 'inherit',
    elevation: 0,
    sx: {
      boxShadow: theme.shadows[10]
    }
  };

  return (
    <>
      {!matchDownMD ? (
        <AppBar open={open} {...appBar}>
          {mainHeader}
        </AppBar>
      ) : (
        <AppBar {...appBar}>{mainHeader}</AppBar>
      )}
    </>
  );
};

Header.propTypes = {
  open: PropTypes.bool,
  handleDrawerToggle: PropTypes.func
};

export default Header;
