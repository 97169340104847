import { EnvConfig } from './config.d';

let envConfig: EnvConfig = await import('./env.development.json');

if (process.env.REACT_APP_ENV === 'production') {
  envConfig = await import('./env.production.json');
} else if (process.env.REACT_APP_ENV === 'staging') {
  envConfig = await import('./env.staging.json');
} else if (process.env.REACT_APP_ENV === 'development') {
  envConfig = await import('./env.development.json');
} else if (process.env.REACT_APP_ENV === 'prodDev') {
  envConfig = await import('./env.production-local-backend.json');
}

export default envConfig;
