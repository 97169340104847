import React from 'react';
import { Button, Typography } from '@mui/material';

const CompanyButton = () => {
  return (
    <Button
      href="https://threshpower.com/"
      sx={{
        borderRadius: 2,
        '&:hover': { backgroundColor: 'rgba(0, 0, 0, 0.1)' }
      }}
    >
      <Typography variant="h4" sx={{ color: 'activeScheme.onPrimary' }}>
        Company
      </Typography>
    </Button>
  );
};
export default CompanyButton;
