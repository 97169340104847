import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet } from 'react-router-dom';

// material-ui
import { Box, Stack } from '@mui/material';
import { useTheme } from '@mui/material/styles';

// project import
import Breadcrumbs from 'components/@extended/Breadcrumbs';
import Drawer from './Drawer';
import Header from './Header';
import { HeightContext } from './HeightContext';

// types
import { openDrawer } from 'store/reducers/menu';

// Hooks
import { useLocation } from 'react-router-dom';

const MainLayout = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const location = useLocation();
  const [height, setHeight] = useState('fit-content');

  // Drawer toggling
  const { drawerOpen } = useSelector((state) => state.menu);
  const [open, setOpen] = useState(drawerOpen);
  const handleDrawerToggle = () => {
    setOpen(!open);
    dispatch(openDrawer({ drawerOpen: !open }));
  };
  useEffect(() => {
    if (open !== drawerOpen) setOpen(drawerOpen);
  }, [drawerOpen]);

  return (
    <HeightContext.Provider value={{ setHeight }}>
      <Box // Outermost div. i.e the whole page
        sx={{
          display: 'flex', // So the Drawer (on the left) can squish the main content (on the right)
          // Make sure to fill up the whole page
          height,
          minHeight: '100svh',
          minHeight: '100vh', // Old browser fallback for svh
          width: '100%',
          // Visual styling
          backgroundColor: 'activeScheme.surfaceBright'
        }}
      >
        <Drawer open={open} handleDrawerToggle={handleDrawerToggle} />
        <Stack // Header, and the main content
          direction="column"
          sx={{
            // Width
            flexGrow: 1, // Take up all the available space not used by the Drawer (horizontally and vertically)
            minWidth: '0px',
            // Height
            minHeight: 0
          }}
        >
          <Header open={open} handleDrawerToggle={handleDrawerToggle} />
          <Stack // Main content container. Vertical layout. Breadcrumbs, and everything else
            spacing={2}
            sx={{
              p: { xs: 2, sm: 4 },
              flexGrow: 1, // Take up all the available space not used by the Header
              overflow: 'hidden' // Respect the height of the parent Stack. Don't allow page contents to overflow
            }}
          >
            <Breadcrumbs />
            <Outlet />
          </Stack>
        </Stack>
      </Box>
    </HeightContext.Provider>
  );
};

export default MainLayout;
