import React from 'react';
import PropTypes from 'prop-types';

import { Paper, Toolbar, Typography } from '@mui/material';

// ==============================|| MINIMAL LAYOUT ||============================== //

interface MinimalLayoutProps {
  children: React.ReactNode;
}

const MinimalLayout = ({ children }: MinimalLayoutProps) => {
  return (
    <Paper sx={{ height: '100vh', backgroundColor: 'activeScheme.surfaceBright' }}>
      <Header />

      {children}
    </Paper>
  );
};

MinimalLayout.propTypes = {
  children: PropTypes.node
};

export default MinimalLayout;

const Header = () => {
  return (
    <Toolbar sx={{ backgroundColor: 'activeScheme.primary' }}>
      <Typography variant="h3" sx={{ color: 'activeScheme.onPrimary' }}>
        Thresh Power
      </Typography>
    </Toolbar>
  )
}
