// assets
import { ProfileOutlined, InfoCircleOutlined } from '@ant-design/icons';

// ==============================|| MENU ITEMS - DASHBOARD ||============================== //

// Should mirror routes in src/routes/index.js
const objects = {
  id: 'group-objects',
  title: 'Navigation',
  type: 'group',
  children: [
    {
      id: 'projects',
      title: 'Projects',
      type: 'item',
      url: '/projects',
      icon: ProfileOutlined
    }
  ]
};

export default objects;
