// Returns {
//   uploadPromise: Promise.all(promises),
//   failedFiles: All files that failed to upload
// }
export default (file_and_path_objects: any, projectId: number, setFailedFileNames: any, setUploadedFileNames: any, uploadFile: any) => {
  const promises: Promise<any>[] = [];
  // Can't use setFailedFileNames because it's async. 
  // We want to return the failed files from this function 
  // so need to have this second way of tracking the failed files
  const failedFiles: any[] = [];

  file_and_path_objects.forEach((file_and_path_object: any) => {
    const file = file_and_path_object.file;
    const path = file_and_path_object.path;
    promises.push(
      new Promise<void>((resolve) => {
        uploadFileInChunksInSerial(projectId, file, path, uploadFile)
          .then(() => {
            setUploadedFileNames((prev: any) => [...prev, file.name]);
            resolve();
          })
          .catch(() => {
            failedFiles.push(file);
            setFailedFileNames((prev: any) => [...prev, file.name]);
            // TODO retry failed uploads
          });
      })
    );
  });
  const uploadPromise = Promise.all(promises);
  return { uploadPromise, failedFiles };
};


/*
  * This function exists because the max request size for Cloud Run is 32MB (for http1) https://cloud.google.com/run/quotas#request_limits
  * While there is no limit for http2, gunicorn did not support http2 requests when I tried last: https://cloud.google.com/run/docs/configuring/http2
  * So here we are. 
  * Ideally this function goes away when we move off Cloud Run and we just `uploadFile` directly
  * 
  * We upload chunks in serial because otherwise there is a race condition where the server tries to reassemble the file before all the chunks are fully uploaded
  * A better way would be to send all chunks in parallel and then send a final request to trigger the reassembly of the file. 
  * But since our chunk size is so large and our large files are only ~100MB, we won't see a ton of benefit from that parallelism.
  */
const uploadFileInChunksInSerial = (
  projectId: number,
  file: File,
  path: string,
  uploadFormDataFn: (params: { projectId: number; formData: FormData }) => Promise<void>
) => {
  return new Promise<void>(async (resolve, reject) => {
    const chunkSize = 16 * 1024 * 1024; // 16MB
    const totalChunks = Math.ceil(file.size / chunkSize);

    try {
      for (let i = 0; i < totalChunks; i++) {
        const start = i * chunkSize;
        const end = Math.min(start + chunkSize, file.size);
        const chunk = file.slice(start, end);

        const formData = new FormData();
        formData.append('chunk', chunk);
        formData.append('chunkNumber', `${i + 1}`);
        formData.append('totalChunks', `${totalChunks}`);
        formData.append('fileName', file.name);
        formData.append('filePath', path);

        await uploadFormDataFn({ projectId, formData });
      }

      resolve();
    } catch (error) {
      reject(error);
    }
  });
};