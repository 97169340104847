import React from 'react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

import Routes from './routes';
import ThemeCustomization from './themes';
import ScrollTop from './components/ScrollTop';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { CreateProjectFormProvider } from './pages/project-create/Context';
import { ProjectProvider } from './pages/project/ProjectProvider';

// import { worker } from './mocks/browser';
// if (process.env.NODE_ENV === 'development') {
//   worker.start({ onUnhandledRequest: 'bypass' });
// }

// ==============================|| APP - THEME, ROUTER, LOCAL  ||============================== //
const queryClient = new QueryClient();

const App = () => {
  return (
    <ThemeCustomization>
      <ScrollTop>
        <QueryClientProvider client={queryClient}>
          <CreateProjectFormProvider>
            <ProjectProvider>
              <Routes />
              <ToastContainer />
            </ProjectProvider>
          </CreateProjectFormProvider>
        </QueryClientProvider>
      </ScrollTop>
    </ThemeCustomization>
  );
};

export default App;
