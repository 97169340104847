import React, { createContext, useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import uploadFiles from '../../forms/FileUpload/uploadFiles';
import {
  useCreateProjectMutation,
  useStartJobForProjectMutation,
  useUploadFormDataMutation
} from '../../store/services/dueDiligenceServer';
import { useNavigate, Link } from 'react-router-dom';

const CreateProjectFormContext = createContext({});

export const useCreateProject = () => useContext(CreateProjectFormContext);

/**
 * Context provider for the Create Project form.
 * It exist separately from the CreateProjectForm component so that
 * users can navigate away while the form is submitting (i.e. the project is being created).

 * @param {object} props - The properties object.
 * @param {React.ReactNode} props.children - The child components.
 * @returns {JSX.Element} The context provider component.
 */
export const CreateProjectFormProvider = ({ children }: any) => {
  const [uploadedFileNames, setUploadedFileNames] = useState([]);
  const [failedFileNames, setFailedFileNames] = useState([]);
  const [startJobForProject] = useStartJobForProjectMutation();
  const [createProject] = useCreateProjectMutation();
  const navigate = useNavigate();
  const [uploadFormData] = useUploadFormDataMutation();

  const resetState = () => {
    setUploadedFileNames([]);
    setFailedFileNames([]);
  };

  const createProjectAsync = async (name: string) => {
    try {
      const response = await createProject({ name }).unwrap();
      return response.project;
    } catch (error: any) {
      console.error('Error creating project', error);
      if (error.status === 403) {
        toast.error(
          'Sorry, you do not have permission to create a project. Contact lyon@threshpower.com to upgrade your account.'
        );
      } else {
        toast.error(
          'Sorry, we had trouble creating your project. Try again, or email me at lyon@threshpower.com'
        );
      }
      throw error;
    }
  };

  const uploadFilesAsync = async (files: any, projectId: number) => {
    try {
      const { uploadPromise, failedFiles } = uploadFiles(
        files,
        projectId,
        setUploadedFileNames,
        setFailedFileNames,
        uploadFormData
      );
      await uploadPromise;
      return failedFiles;
    } catch (error) {
      console.error('Error uploading files', error);
      toast.error(
        'Sorry, we had trouble uploading your files. Try again, or email me at lyon@threshpower.com'
      );
      throw error;
    }
  };

  const startJobForProjectAsync = async (projectId: number) => {
    try {
      await startJobForProject(projectId).unwrap();
    } catch (error) {
      console.error('Error starting job', error);
      toast.error(
        'Sorry, we had trouble processing your files. Try again, or email me at lyon@threshpower.com'
      );
      throw error;
    }
  };

  const successToast = (projectId: number, inputName: string) => {
    toast.success(
      <div>
        {`Your project called `}
        <Link
          to={`/projects/${projectId}/files`}
          style={{ color: 'blue', textDecoration: 'underline' }}
          onClick={() => toast.dismiss()}
        >
          {inputName}
        </Link>
        {` has been created!`}
      </div>
    );
  };

  // inputFiles is list of objects
  const createProjectEtcAsync = async (inputName: string, inputFiles: any[]) => {
    try {
      // Step 1. Create Project
      const project = await createProjectAsync(inputName);

      // Step 2. Upload Files
      const failedFiles = await uploadFilesAsync(inputFiles, project.id);

      // Step 3. Start Async Job to process the files
      await startJobForProjectAsync(project.id);

      // Step 4. Maybe navigate to the project page
      const failedFileNamesStatic = failedFiles.map((file) => file.name);
      if (window.location.pathname === '/projects/create') {
        navigate(`/projects/${project.id}/files`, {
          state: { failedFileNames: failedFileNamesStatic }
        });
      } else {
        successToast(project.id, inputName);
      }
    } catch (error) {
      // Error handling already done in individual functions.
      // We only reraise in the individual functions so that we can exit the 'try' flow
    }
  };

  return (
    <CreateProjectFormContext.Provider
      value={{ uploadedFileNames, failedFileNames, createProjectEtcAsync, resetState }}
    >
      {children}
    </CreateProjectFormContext.Provider>
  );
};

CreateProjectFormProvider.propTypes = {
  children: PropTypes.node
};
