import React from 'react';
import logo from 'assets/images/icons/thresh_logo_icon_large.png';

// ==============================|| LOGO SVG ||============================== //

const Logo = () => {
  return <img src={logo} alt="Thresh" height="30" />;
};

export default Logo;
