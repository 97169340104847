import React from 'react';
import { lazy } from 'react';

// project import
import Loadable from '../components/Loadable';
import MinimalLayout from '../layout/MinimalLayout';

// render - login
// const AuthLogin = Loadable(lazy(() => import('../pages/authentication/Login')));
// const AuthRegister = Loadable(lazy(() => import('../pages/authentication/Register')));
const Login = Loadable(lazy(() => import('../pages/login')));

// ==============================|| AUTH ROUTING ||============================== //

const LoginRoutes = {
  path: '/login',
  element: (
    <MinimalLayout>
      <Login />
    </MinimalLayout>
  )
};

export default LoginRoutes;
