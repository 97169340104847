const tabHeight = '32px'; // default: '48px'

export default function Tabs() {
  return {
    MuiTabs: {
      styleOverrides: {
        root: {
          minHeight: tabHeight,
          height: tabHeight
        }
      }
    },
    MuiTab: {
      styleOverrides: {
        root: {
          minHeight: tabHeight,
          height: tabHeight
        }
      }
    }
  };
}
