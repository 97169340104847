// material-ui
import React from 'react';
import Environment from './Environment';
import { Stack } from '@mui/material';
import CompanyButton from './CompanyButton';
import Profile from './Profile';
import ProjectName from './ProjectName';

const HeaderContent = () => {
  return (
    <Stack direction="row" justifyContent="space-between" sx={{ flexGrow: 1 }}>
      <Stack direction="row" spacing={2} alignItems="center" sx={{ ml: 2 }}>
        <ProjectName />
        <Environment />
      </Stack>
      <Stack direction="row" spacing={2} justifyContent="flex-end">
        <CompanyButton />
        <Profile />
      </Stack>
    </Stack>
  );
};

export default HeaderContent;
