// third-party
import { merge } from 'lodash';

import Typography from './Typography';
import Tabs from './Tabs';

// ==============================|| OVERRIDES - MAIN ||============================== //

export default function ComponentsOverrides() {
  return merge(Typography(), Tabs());
}
